import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <section className="lost center">
      <h1>Sorry, that page doesn't exist.</h1>
      <p>Try returning to the <Link to="/">home page</Link>.</p>
    </section>
  </Layout>
)

export default NotFoundPage
